export enum GameMechanicType {
	"TAP_TO_PLAY" = "tap-to-play",
	"CUSTOM_GAME" = "custom-game",
}

export enum ShareStatus {
	SHARED = "shared",
	PENDING = "pending",
	NOT_SHARED = "not-shared",
}

export enum ItemStatus {
    DROPPED = "dropped",
    CLAIMED = "claimed",
    ALLOCATED = "allocated",
    REDEEMED = "redeemed",
    EXPIRED = "expired",
    NOPRIZE = "no-prize",
}

export interface VItem {
	id?: string;
	dropPoolID?: string;
	campaignID: string;
	segmentID: string;
	categoryID: string;
	walletID?: string;
	prizeID?: string;
	type: string;
	gameMechanicType?: GameMechanicType | null;
	gameCodeTemplateID?: string | null;
	categoryConfig: LoyaltyConfig | null;
	itemStatus: ItemStatus; //dropped, claimed, allocated, redeemed, expired
	voucherCode?: string;
	marker?: string;
	asset?: string;
	latitude: number;
	longitude: number;
	dropDate: string;
	claimDate?: string;
	redeemDate?: string;
	winning?: boolean;
	shareStatus: ShareStatus;
	shareID: null | string;
	loyaltyState: LoyaltyState | null;
}

export interface LoyaltyConfig {
    enabled: boolean
    prepopulated: boolean
    repeatable: boolean
    repeatableTimes: number
    initLoyaltyVATText: string
    initLoyaltyFaceCardText: string
    endLoyaltyVATText: string
    endLoyaltyFaceCardText: string
    loyaltyVATImage: string
    loyaltyOnRedeem: boolean
    onRedeem: boolean
    showLoyaltyVATAtStart: boolean
    showCollectedVATs: boolean
    hideLoyaltyVATAtEnd: boolean
    hideCollectedVATsAtEnd: boolean
    steps: LoyaltyStep[]
    total: number
    iterate: number
}

export interface LoyaltyStep {
    name: string
    collectableCategoryID: string
    rewardCategoryID: string
    rewardPrizeID: string
    loyaltyVATImage: string
    loyaltyVATText: string
    loyaltyFaceCardText: string
}


export interface LoyaltyState {
    complete: boolean
    collected: number
    collectedList: string[]
    unCollectedList: string[]
    total: number
    initText: string
    image: string
}

