<template>
    <div>
        <video
            @click="playVideo()"
            class="center-video"
            :style="{ opacity: videoReadyState == 4 ? 1 : 0 }"
            id="vid"
            autoplay
            playsinline
        >
            <!-- <video id="vid" autoplay loop muted playsinline> -->
            <source :src="element.attributes.src" />
        </video>
        <LoadingComponent v-if="loading" />
    </div>
</template>

<script lang="ts">
import { Action } from "@/types/customPage"
import LoadingComponent from "@/components/utils/LoadingComponent.vue"
import { defineComponent } from "vue"
import { prizeStore } from "@/store/pinia_modules/prizeStore"
import { vItemStore } from "@/store/pinia_modules/vItemStore"
import { navigationStore } from "@/store/pinia_modules/navigationStore"

export default defineComponent({
    props: {
        element: {
            type: Object,
            required: true,
        },
    },
    components: { LoadingComponent },
    data() {
        return {
            loading: false,
            video: document.querySelector("#vid") as HTMLVideoElement,
            windowWidth: 0,
            windowHeight: 0,
            videoHeight: 0,
            videoWidth: 0,
            videoReadyState: 0,
            videoStatus: "loading",
        }
    },

    mounted() {
        this.loading = true
        this.video = document.querySelector("#vid") as HTMLVideoElement
        this.addVideoListeners()
    },
    computed: {
        clickToPlay() {
            return prizeStore().getClickToPlay
        },
    },
    methods: {
        addVideoListeners() {
            this.video.addEventListener("loadeddata", (e: any) => {
                this.windowWidth = window.innerWidth
                this.windowHeight = window.innerHeight
                this.videoWidth = e.target.clientWidth
                this.videoHeight = e.target.clientHeight
                this.loading = false
            })
            const checkLoadedData = setInterval(() => {
                this.videoReadyState = this.video.readyState

                if (this.video && this.video.readyState == 4) {
                    this.videoStatus = "ready"
                    if (!this.clickToPlay) {
                        this.video.play()
                    } else {
                        this.video.pause()
                    }
                    clearInterval(checkLoadedData)
                }
            }, 100)
            this.video.addEventListener("ended", async () => {
                try {
                    this.videoStatus = "ended"
                    this.video.pause()
                    if (prizeStore().hasPrize) {
                        const isWinning = prizeStore().getIsWinning
                        console.log("isWinning : ", isWinning)
                        if (isWinning) {
                            await vItemStore().allocatePrize()
                            this.$emit("event", "redeem")
                        } else {
                            await vItemStore().setNoPrize()
                            this.$emit("event", "losingPrize")
                        }
                    }
                } catch (e) {
                    console.error(e)
                }
            })
        },
        async playVideo() {
            // if (this.videoStatus != "ended") {
            if (this.clickToPlay) {
                this.video.play()
            }

            // } else if (this.videoStatus == "ended") {
            // 	if (store.getters["prizeStore/getIsWinning"]) {
            // 		store.dispatch("itemStore/allocatePrize");
            // 		this.$emit("event", "redeem");
            // 	} else {
            // 		await store.dispatch("itemStore/setNoPrize");
            // 		this.$emit("event", "losingPrize");
            // 	}
            // }
        },

        gotoPage(page: string) {
            this.$router
                .push({
                    path: page,
                })
                .catch(() => {
                    //
                })
        },

        doAction(action: Action) {
            navigationStore().emitAction(action)
        },
    },
})
</script>

<style scoped lang="scss">
.center-video {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center center;
}
.message {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    color: #fff;
}
</style>
