<template>
    <div id="holder">
        <iframe
            id="game-iframe"
            style="width: 100%; height: 100%; border-style: none"
            :srcdoc="srcDoc"
        ></iframe>
        <LoadingComponent v-if="loading" />
    </div>
</template>

<script lang="ts">
import ModalComponent from "@/components/utils/ModalComponent.vue"
import LoadingComponent from "@/components/utils/LoadingComponent.vue"
import { defineComponent } from "vue"
import { VItem } from "@/types/vItem"
import { Category } from "@/types/category"
import { vItemStore } from "@/store/pinia_modules/vItemStore"
import { categoryStore } from "@/store/pinia_modules/categoryStore"
import { prizeStore } from "@/store/pinia_modules/prizeStore"
import { navigationStore } from "@/store/pinia_modules/navigationStore"
import { Prize } from "@/types/prize"

export default defineComponent({
    components: { ModalComponent, LoadingComponent },
    data() {
        return {
            showClaimModal: false,
            selectedItemID: "",
            srcDoc: "",
            iframeElement: null as HTMLIFrameElement | null,
            loading: false,
        }
    },

    mounted() {
        this.getGameCode()
        this.iframeListener()
    },
    unmounted() {
        window.removeEventListener("message", this.messageEvent)
    },
    computed: {
        vItem(): VItem | undefined {
            return vItemStore().getSelectedItem
        },
    },
    methods: {
        async getGameCode() {
            this.srcDoc = await vItemStore().renderGameCodeTemplate({
                templateID:
                    this.getCategoryByID(this.vItem!.categoryID)!
                        .gameCodeTemplateID || "",
                vItemID: this.vItem!.id || "",
            })
        },
        getCategoryByID(id: string): Category | undefined {
            return categoryStore().getItemByID(id)
        },
        getPrizeByID(id: string): Prize | undefined {
            return prizeStore().getPrizeByID(id)
        },

        sendMessage(event: string, message: any) {
            const msg = JSON.stringify({
                event: event,
                message: message,
            })
            this.iframeElement = document.getElementById(
                "game-iframe"
            ) as HTMLIFrameElement
            this.iframeElement!.contentWindow!.postMessage(msg, "*")
        },
        iframeListener() {
            this.iframeElement = document.getElementById(
                "game-iframe"
            ) as HTMLIFrameElement

            window.addEventListener("message", this.messageEvent, false)
        },
        messageEvent(e: any) {
            try {
                const data = JSON.parse(e.data)
                switch (data.event) {
                    case "emitAction":
                        navigationStore().emitAction(data.message)
                        break
                    case "allocatePrize":
                        this.allocatePrize()
                        break
                    case "setLosing":
                        this.setLosing()
                        break
                }
            } catch (e) {
                console.error(e)
            }
        },
        async allocatePrize() {
            try {
                const vItem = await vItemStore().allocateGamePrize({
                    vItemID: this.vItem!.id || "",
                })
                if (vItem.winning) {
                    const prize = this.getPrizeByID(vItem.prizeID!)
                    this.sendMessage("prizeAllocated", { prize, vItem })
                } else {
                    this.setLosing()
                }
            } catch (e) {
                console.error(e)
            }
        },
        async setLosing() {
            try {
                const resp = await vItemStore().setNoPrize()
                if (resp) {
                    this.sendMessage("losingSet", {})
                }
            } catch (e) {
                console.error(e)
            }
        },
    },
})
</script>

<style scoped lang="scss">
#holder {
    width: 100%;
    height: 100%;
    background-color: #000;
}
#ar-iframe {
    width: 100%;
    height: 100%;
    border-style: none;
    background-color: #000;
}

a-scene {
    width: 100%;
    height: 100%;
}
.ar-loading {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #000;
    z-index: 100;
}
.cross-hair {
    position: absolute;
    top: 50%;
    left: 50%;
    .hair {
        width: 200px;
        border-style: solid;
        border-width: 1px 0 0 0;
        border-color: #fff;
        position: absolute;
        transform: translate(-50%, -50%);
        opacity: 0.2;
    }
    .vertical {
        transform: rotate(90deg);
        left: -100px;
    }
}
</style>
