

class APIError extends Error {
    constructor (message: string) {
        super(message);
        this.name = "APIError";
    }
}

class ConstraintError extends Error {
    constructor (message: string) {
        super(message);
        this.name = "ConstraintError";
    }
}

export { APIError, ConstraintError };
